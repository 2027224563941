import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AlertController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

    private alertCtrl: AlertController;
    private translate: TranslateService

    constructor(injector: Injector) {
        this.alertCtrl = injector.get(AlertController);
        this.translate = injector.get(TranslateService);
    }

    private static shouldShowError(error: HttpErrorResponse): boolean {
        if (error.status !== 401) {
            return true;
        }
        if (error.message === '') {
            return false;
        }
        if (error.url?.includes('login')) {
            return false;
        }
        return !error.url?.includes('logout');
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(e => this.handleError(e)));
    }

    private handleError(e: ErrorType): Observable<never> {
        if (e instanceof HttpErrorResponse) {
            this.handleHttpErrorResponse(e);
        } else {
            this.handleDomException(e);
        }

        return throwError(e);
    }

    async handleHttpErrorResponse(e: HttpErrorResponse) {
        const errore = await this.getTrans('ERRORE');
        if (HttpRequestInterceptor.shouldShowError(e)) {
            this.alertCtrl.create({
                message: `${e.error?.message ?? e.message ?? ''} ${e.error?.detail ?? ''}`.trim(),
                backdropDismiss: true,
                header: e.error?.title ?? e.statusText ?? 'errore'
            });
        }
    }

    handleDomException(e: DOMException) {
        this.alertCtrl.create({
            message: e.message,
            header: e.name,
            backdropDismiss: true
        });
    }

    getTrans(key: string | string[], params: any = {}) {
        return this.translate.get(key, params).toPromise();
    }
}

type ErrorType = HttpErrorResponse | DOMException;
