// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    defaultLang: 'it',
    defaultUnit: 'km',
    androidHeaderColor: '#ac2ca3',
    serverUrl: 'https://3boa9un66j.execute-api.eu-west-2.amazonaws.com/prod',
    appUrl: 'https://3boa9un66j.execute-api.eu-west-2.amazonaws.com',
    appImageUrl: 'https://trynearme.app/assets/icons/apple-touch-icon.png',
    appId: '',
    presignedUrl: 'https://3boa9un66j.execute-api.eu-west-2.amazonaws.com/prod',
    pageLimit: {
        lastScanned: 6,
        allWines: 100
    },
    confirm: { email: '', password: '' },
    mock: false,
    imgUrlRoot: 'https://cevino.s3.eu-west-2.amazonaws.com/thumbs/',
    version: '1.0.6',
    //cognito: {
    //    region: 'eu-west-2', identityPoolRegion: 'eu-west-2', userPoolId: 'eu-west-2_FDMhQwEBG',
    //    userPoolWebClientId: '7jd3ead2k53v77bb7f8nvgquvo',
    //    cookieStorage: {
    //        // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    //        domain: 'localhost',
    //        // OPTIONAL - Cookie path
    //        path: '/',
    //        // OPTIONAL - Cookie expiration in days
    //        expires: 365,
    //        // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
    //        //sameSite: "strict" | "lax",
    //        // OPTIONAL - Cookie secure flag
    //        // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    //        secure: false
    //    },
    //}
    cognito: {
        region: 'eu-west-2', identityPoolRegion: 'eu-west-2', userPoolId: 'eu-west-2_FDMhQwEBG',
        userPoolWebClientId: '7jd3ead2k53v77bb7f8nvgquvo',
        //cookieStorage: {
        //    // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        //    domain: 'wineandcellars.com',
        //    // OPTIONAL - Cookie path
        //    path: '/',
        //    // OPTIONAL - Cookie expiration in days
        //    expires: 365,
        //    // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
        //    //sameSite: "strict" | "lax",
        //    // OPTIONAL - Cookie secure flag
        //    // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        //    secure: true
        //},
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
