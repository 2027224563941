import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {UserService} from '@services/user.service';

@Injectable()
export class AuthExpiredInterceptor implements HttpInterceptor {

    constructor(
        private userService: UserService,
        private router: Router
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap(
                () => {
                },
                (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        if (
                            err.status === 401 &&
                            err.url
                        ) {
                            this.userService.logout();
                            this.router.navigate(['/']);
                        }
                    }
                }
            )
        );
    }
}
