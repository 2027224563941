import { Injectable } from '@angular/core';
import {environment} from '@env';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {User} from '@model/user';
import { Observable, of } from 'rxjs';
import Amplify, { Auth } from 'aws-amplify';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private readonly baseUrl = `${environment.serverUrl}`;
    private userList: User[];
    private userThumbRoot = "https://cevino.s3.eu-west-2.amazonaws.com/usersthumb/";
    private bDownloading = false;

    constructor(private http: HttpClient) {
        //this.userList = this.populateUserList();
        this.readUserListFromLocal();
    }

    readUserListFromLocal() {
        console.log("*** readUserListFromLocal begin: " + JSON.stringify(this.userList));
        try {
            this.userList = JSON.parse(localStorage.getItem('userList'));
            if (this.userList.length === 0) {
                this.userList = [];
                var userObj = JSON.parse(localStorage.getItem('userObj'));
                if (userObj) this.userList.push(userObj);
            }
        } catch (err) {
            this.userList = [];
            var userObj = JSON.parse(localStorage.getItem('userObj'));
            if (userObj) this.userList.push(userObj);
            localStorage.setItem('userList', JSON.stringify(this.userList));
        }
        console.log("*** readUserListFromLocal end: " + JSON.stringify(this.userList));
    }

    getCurrentUser(): string {
        return localStorage.getItem('user');
    }

    getCurrentAlias(): string {
        return localStorage.getItem('userAlias');
    }

    getCurrentUserObj(): User {
        //localStorage.setItem('usrTime', new Date().getTime().toString());
        //userObj = this.getUserObjDb(this.getCurrentUser());
        var usrTime = new Date(Number(localStorage.getItem('usrTime')));
        console.log("*** READ from usrTime: " + usrTime.getTime());
        var nowstr = new Date();
        console.log("*** READ from nowstr: " + nowstr.getTime());
        var userObj = JSON.parse(localStorage.getItem('userObj'));
        if (typeof usrTime == 'undefined' || usrTime === null) {
            usrTime = new Date();
            localStorage.setItem('usrTime', usrTime.getTime().toString());
        } else {
            var minutesElapsed = (nowstr.getTime() - usrTime.getTime()) / 1000 / 60;
            if (minutesElapsed > 5) {
                console.log("*** READ User from db: " + minutesElapsed);
                var userid = this.getCurrentUser();
                var bFound = false;
                var userObj = JSON.parse(localStorage.getItem('userObj'));
                if (!this.bDownloading) {
                    this.bDownloading = true;
                    this.http.get<User>(`${this.baseUrl}/users/${userid}`).subscribe(
                        value => {
                            userObj = value;
                            localStorage.setItem('userObj', JSON.stringify(userObj));
                            console.log('Downloaded user: ' + JSON.stringify(userObj));
                            try {
                                if (!this.userList && userObj) { this.userList = []; this.userList.push(userObj); console.log('userList: ' + JSON.stringify(this.userList));}
                            } catch (err) { }
                            this.bDownloading = false;
                        }
                    );
                    localStorage.setItem('usrTime', nowstr.getTime().toString());
                }
            } else console.log("*** READ User from local: " + minutesElapsed);
        }
        
        return userObj;
    }

    //getUserObjDb(userid: string) {
    //    var userObj = JSON.parse(localStorage.getItem('userObj'));
    //    this.http.get<User>(`${this.baseUrl}/users/${userid}`).subscribe(
    //        value => {
    //            userObj = value;
    //            localStorage.setItem('userObj', JSON.stringify(userObj));
    //            console.log('Downloaded user: ' + JSON.stringify(userObj));
    //        }
    //    );
    //}

    //getUserObjDb(userid: string):Observable<User> {
    //    return this.http.get<User>(`${this.baseUrl}/users/${userid}`).pipe();
    //}

    //getUserDetail(userid: string): Observable<User> {
    //    const headerDict = {
    //        'Content-Type': 'application/json',
    //        Accept: 'application/json, text/plain, */*',
    //        //'Access-Control-Allow-Headers': 'Content-Type,userid',
    //        userid: this.getCurrentAlias()
    //    };
    //    const requestOptions = {
    //        headers: new HttpHeaders(headerDict),
    //    };
    //    return this.http.get<User>(`${this.baseUrl}/users/${userid}`, requestOptions).pipe();
    //}

    getAliasObj(): User {
        var usr = this.getCurrentAlias();
        var userObj = null;
        if (typeof usr !== 'undefined' && usr !== 'undefined') {
            userObj = this.getUserObj(usr);
        } else {
            console.log("getAliasObj usr null or undefined");
        }
        return userObj;
    }

    getUserObj(usrName: string): User {
        var res: User;
        var bFound = false;
        console.log('user service getUserObj [' + usrName+'] this.userList: ' + JSON.stringify(this.userList));
        if (this.userList === null || this.userList[0] === null) {
            console.log("user service getUserObj: this.userList === null");
            if (usrName !== 'undefined')
                this.getMissingUser(usrName);
        } else {
            this.userList.forEach(element => {
                if (element.username == usrName) {
                    res = element;
                    bFound = true;
                }
            });
            if (!bFound) {
                console.log('getUserObj not fount: ' + usrName + " userlist: " + JSON.stringify(this.userList));
                if (usrName !== 'undefined')
                    this.getMissingUser(usrName);
            }
        }
        return res;
    }

    async getMissingUser(userid: string) {
        var userObj: User = {};
        var bFound = false;
        console.log('userService getMissingUser INIT... userid: ' + userid);
        
        if (!this.bDownloading) {
            this.bDownloading = true;
            console.log('getMissingUser REST get user: ' + userid + " userlist: " + JSON.stringify(this.userList));
            await this.http.get<User>(`${this.baseUrl}/users/${userid}`).subscribe(
                value => {
                    userObj = value;
                    console.log('getMissingUser Downloaded OK new other user: ' + JSON.stringify(userObj));
                    //Verifico prima di inserire
                    console.log('getMissingUser this.userList: ' + JSON.stringify(this.userList));
                    if (typeof this.userList !== 'undefined' && this.userList !== null) {
                        console.log('getMissingUser this.userList: ok');
                        bFound = false;
                        this.userList.forEach(element => {
                            try {
                                if (element.username == userid) {
                                    bFound = true;
                                    console.log('...Found');
                                }
                            } catch (err) {
                                bFound = false;
                                console.log('err foreach ...not Found');
                            }
                        });
                        console.log('check bFound 1:' + bFound);
                    } else {
                        bFound = false;
                        console.log('getMissingUser this.userList: null or undefined');
                    }
                    console.log('check bFound 2:' + bFound);
                    if (!bFound && userObj) {
                        console.log('...not found');
                        this.userList.push(userObj);
                        localStorage.setItem('userList', JSON.stringify(this.userList));
                        var currUser = localStorage.getItem('user');
                        if (currUser === userObj.username) {
                            localStorage.setItem('userAlias', userObj.userAlias);
                        }
                        console.log('getMissingUser Downloaded END new other user: ' + JSON.stringify(userObj) + " userlist: " + JSON.stringify(this.userList));
                    }
                    console.log('getMissingUser Downloaded END this.userList: ' + JSON.stringify(this.userList));
                    localStorage.setItem('userList', JSON.stringify(this.userList));
                    this.bDownloading = false;
                },
                error => {
                    this.bDownloading = false;
                    console.error('getMissingUser error: ' + JSON.stringify(error));
                },
                () => {
                    this.bDownloading = false;
                    console.log('getMissingUser complete this.userList: ' + JSON.stringify(this.userList));
                }
            );
        } else {
            console.log('getMissingUser new other user: ' + userid + " Already downloading...");
        }
        console.log('userService getMissingUser DONE... userid: ' + userid);
    }

    getCurrUserSubs(): User[] {
        return this.getUserSubs(this.getCurrentUser());
    }

    isConnectedToSubscibed(): boolean {
        return this.getCurrentUser() != this.getCurrentAlias();
    }

    getUserSubs(usrName: string): User[] {
        var sSubstList = usrName+",";
        var aSubstObjList = [];
        var bFound = false;
        console.log('userService getUserSubs INIT... userid: ' + usrName);

        if (this.userList == null || this.userList.length === 0) {
            console.log('getUserSubs userList empty: ' + usrName + " userlist: " + JSON.stringify(this.userList));
            this.getMissingUser(usrName);
            return [];
        }

        this.userList.forEach(element => {
            try {
                if (element.username == usrName) {
                    sSubstList += element.subscribedTo;
                    bFound = true;
                }
            } catch (err) {
                bFound = false;
                console.log('userService getUserSubs try err... userid: ' + usrName);
            }
        });
        if (!bFound) {
            console.log('getUserSubs not found 2: ' + usrName + " userlist: " + JSON.stringify(this.userList));
            this.getMissingUser(usrName);
        }
        //var asSubstList = sSubstList.split(",");
        if (sSubstList.length>0)
            this.userList.forEach(element => {
                try {
                    if (sSubstList.indexOf(element.username) > -1) aSubstObjList.push(element);
                } catch (err) {
                    console.log('userService getUserSubs try err 2... userid: ' + usrName);
                }
            });

        console.log('userService getUserSubs DONE... userid: ' + usrName);
        return aSubstObjList;
    }

    getCurrUserFollowing(): User[] {
        return this.getUserFollowing(this.getCurrentUser());
    }

    getUserFollowing(usrName: string): User[] {
        var sSubstList = "";
        var aSubstObjList = [];
        var bFound = false;
        console.log('userService getUserFollowing INIT... userid: ' + usrName);

        if (this.userList == null || this.userList.length === 0) {
            console.log('getUserFollowing not found 1: ' + usrName + " userlist: " + JSON.stringify(this.userList));
            this.getMissingUser(usrName);
            return [];
        }

        this.userList.forEach(element => {
            try {
                if (element.username == usrName) {
                    sSubstList = element.following;
                    bFound = true;
                }
            } catch (err) {
                bFound = false;
                console.log('userService getUserFollowing try err... userid: ' + usrName);
            }
        });
        if (!bFound) {
            this.getMissingUser(usrName);
            console.log('getUserFollowing not found 2: ' + usrName + " userlist: " + JSON.stringify(this.userList));
        }
        //var asSubstList = sSubstList.split(",");
        if (typeof sSubstList !== 'undefined' && sSubstList.length > 0) {
            this.userList.forEach(element => {
                try {
                    if (sSubstList.indexOf(element.username) > -1) aSubstObjList.push(element);
                } catch (err) {
                    console.log('userService getUserFollowing try err 2... userid: ' + usrName);
                }
            });
        } else {
            console.log('getUserFollowing user: ' + usrName + " sSubstList: " + sSubstList);
        }

        console.log('userService getUserFollowing DONE... userid: ' + usrName);
        return aSubstObjList;
    }

    getCurrUserFollowers(): User[] {
        return this.getUserFollowers(this.getCurrentUser());
    }

    getUserFollowers(usrName: string): User[] {
        var sSubstList = "";
        var aSubstObjList = [];
        var bFound = false;
        console.log('userService getUserFollowers INIT... userid: ' + usrName);

        if (this.userList == null || this.userList.length === 0) {
            console.log('getUserFollowers not found 1: ' + usrName + " userlist: " + JSON.stringify(this.userList));
            this.getMissingUser(usrName);
            return [];
        }

        this.userList.forEach(element => {
            try {
                if (element.username == usrName) {
                    sSubstList = element.followers;
                    bFound = true;
                }
            } catch (err) {
                bFound = false;
                console.log('userService getUserFollowers try err 1... userid: ' + usrName);
            }
        });
        if (!bFound) {
            console.log('getUserFollowers not found 2: ' + usrName + " userlist: " + JSON.stringify(this.userList));
            this.getMissingUser(usrName);
        }
        //var asSubstList = sSubstList.split(",");
        if (typeof sSubstList !== 'undefined' && sSubstList.length > 0) {
            this.userList.forEach(element => {
                try {
                    if (sSubstList.indexOf(element.username) > -1) aSubstObjList.push(element);
                } catch (err) {
                    console.log('userService getUserFollowers try err 2... userid: ' + usrName);
                }
            });
        } else {
            console.log('getUserFollowers user: ' + usrName + " sSubstList: " + sSubstList);
        }
        console.log('userService getUserFollowers DONE... userid: ' + usrName);
        return aSubstObjList;
    }

    signIn(username: string, password: string): Observable<User> {
        console.log('userService signin INIT...');
        localStorage.setItem('user', username);
        var usr: User;
        console.log('userService call getUserByUsername...');
        usr = this.getUserByUsername(username);
        localStorage.setItem('userAlias', usr.userAlias);
        localStorage.setItem('userObj', JSON.stringify(usr));
        this.readUserListFromLocal();
        console.log('userService signin DONE...');
        return of( usr as User);
    }

    changeAlias(usrAlias: string) {
        var usr: User;
        usr = JSON.parse(localStorage.getItem('userObj'));
        usr.userAlias = usrAlias;
        localStorage.setItem('userAlias', usr.userAlias);
        localStorage.setItem('userObj', JSON.stringify(usr));
    }

    async logout() {
        console.log('userService Logout INIT...');
        Amplify.configure({
            Auth: {

                // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
                //identityPoolId: 'eu-west-2:766db33b-e0ac-43e2-a4ad-bbd1178b163d',

                // REQUIRED - Amazon Cognito Region
                region: environment.cognito.region,

                // OPTIONAL - Amazon Cognito Federated Identity Pool Region 
                // Required only if it's different from Amazon Cognito Region
                identityPoolRegion: environment.cognito.region,

                // OPTIONAL - Amazon Cognito User Pool ID
                userPoolId: environment.cognito.userPoolId,

                // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
                //userPoolWebClientId: '4707hvqfbros3ql4cl7go0865i', // Secret  96qurlveajsc3nmn0o89j5nnecbh204tc2cvijve465p3u2qpd2
                userPoolWebClientId: environment.cognito.userPoolWebClientId, // NO Secret  

                // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
                mandatorySignIn: true,

                // OPTIONAL - Configuration for cookie storage
                // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
                //cookieStorage: environment.cognito.cookieStorage,

                // OPTIONAL - customized storage object
                //storage: MyStorage,

                // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
                //authenticationFlowType: 'USER_PASSWORD_AUTH',

                // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
                //clientMetadata: { myCustomKey: 'myCustomValue' },

                // OPTIONAL - Hosted UI configuration
                //oauth: {
                //    domain: 'winesandcellars.auth.eu-west-2.amazoncognito.com',
                //    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
                //    redirectSignIn: 'http://localhost:4200/',
                //    redirectSignOut: 'http://localhost:4200/', 
                //    responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
                //}
            }
        });
        console.log('userService Logout AWS done...');

        try {
            var username = localStorage.getItem('user');
            await Auth.signOut();
            console.log("Logout OK...");
            localStorage.removeItem('user');
            localStorage.removeItem('userAWS');
            localStorage.removeItem('userAlias');
            localStorage.removeItem('cellars_' + username);
            localStorage.removeItem('userObj');
            console.log('userService Logout DONE...');

        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    getUserByUsername(usrName: string): User {
        var __FOUND = -1;
        var retVal = {};
        if (this.userList === null || this.userList[0] === null) {
            console.log('getUserByUsername this.userList: ', JSON.stringify(this.userList));
            console.log('userService getUserByUsername call getMissingUser...');
            this.getMissingUser(usrName);
            return retVal;
        } else {
            console.log('getUserByUsername else this.userList: ', JSON.stringify(this.userList));
            console.log('getUserByUsername else this.userList.length: ', this.userList.length);
        }
        for (var i = 0; i < this.userList.length; i++) {
            if (this.userList[i].username == usrName) {
                __FOUND = i;
                break;
            }
        }
        if (__FOUND > -1) {
            retVal = this.userList[__FOUND];
        } else {
            console.log('getUserByUsername this.userList: ', JSON.stringify(this.userList));
            console.log('userService getUserByUsername USER NOT FOUND call getMissingUser...');
            this.getMissingUser(usrName);
        }
        return retVal;
    }

    getUserThumb(avatar: string) {
        return this.userThumbRoot + avatar;
    }

    populateMockUserList(): User[]{
        return [
            {
                username: "andreac",
                email: "andrea@capuzzo.biz",
                avatar: "usr_andreac.png",
                id: "1",
                fullName: "Andrea Capuzzo",
                prefLang: "it",
                userType: "STD",
                userDescr: "",
                userAlias: "andreac",
                following: "maurizio",
                followers: "maurizio, alexatest",
                subscribedTo: "labotte",
                eventsRegions: "veneto"
            },
            {
                username: "maurizio",
                email: "mauri.benti@gmail.com",
                avatar: "usr_maurizio.jpeg",
                id: "2",
                fullName: "Maurizio Bentivoglio",
                prefLang: "it",
                userType: "STD",
                userDescr: "",
                userAlias: "maurizio",
                following: "andreac",
                followers: "andreac",
                subscribedTo: "labotte",
                eventsRegions: "veneto"
            },
            {
                username: "barbara",
                email: "bdevido@gmail.com",
                avatar: "",
                id: "3",
                fullName: "Barbara De Vido",
                prefLang: "it",
                userType: "STD",
                userDescr: "",
                userAlias: "barbara",
                following: "",
                followers: "",
                subscribedTo: "labotte",
                eventsRegions: "veneto"
            },
            {
                username: "labotte",
                email: "labotte@capuzzo.biz",
                avatar: "usr_labotte.jpg",
                id: "4",
                fullName: "Enoteca La Botte",
                prefLang: "it",
                userType: "PRO",
                userDescr: "Il luogo di incontro per gli appassionati del vino",
                userAlias: "labotte",
                following: "",
                followers: "",
                subscribedTo: "",
                eventsRegions: "veneto"
            },
            {
                username: "alexatest",
                email: "alexatest@capuzzo.biz",
                avatar: "usr_alexatest.jpg",
                id: "5",
                fullName: "Test user",
                prefLang: "it",
                userType: "STD",
                userDescr: "",
                userAlias: "alexatest",
                following: "",
                followers: "andreac",
                subscribedTo: "",
                eventsRegions: "veneto"
            }
        ];
    }
}